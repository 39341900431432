<template>
  <div class="root">
    <!-- <div class="section-title">
      <div class="title">通知公告</div>
      <router-link class="more" tag="div" to="/notice">更多>></router-link>
    </div> -->
    <div class="section-cnr">
      <div class="section-cnr_slide">
        <el-carousel :interval="3000" arrow="never" indicator-position="none">
          <el-carousel-item v-for="(item,index) in siteSlideshow" :key="index">
            <el-image :src="item.addr" fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
<!--        <div style="margin:15px 0 15px;font-size: 1.2em;box-sizing: border-box;">积分TOP10排行榜</div>-->
<!--        <div>-->
<!--          <div style="display: flex;width: 100%;border-bottom: 1px solid #eee;padding-bottom: 6px;">-->
<!--            <div style="width: 80% ;padding-left: 16%;box-sizing: border-box;font-size: 0.8em">姓名</div>-->
<!--            <div style="font-size: 0.8em">积分</div>-->
<!--          </div>-->
<!--          <div v-for="(item,index) in top10" :key="index" style="display: flex;width: 100%;" :class="index%2 == 1 ?'bg1':''">-->
<!--            <div class="" style="display: flex;width: 80%;padding: 0.42em 0;box-sizing: border-box;align-items: center">-->
<!--              <div style="text-align: center" :class="(index == 0?'bg2':(index == 1?'bg3':(index == 2?'bg4':'bg5')))">{{ index + 1 }}</div>-->
<!--              <div style="width: 60%">{{ item.studentName }}</div>-->
<!--            </div>-->
<!--            <div style="flex: 1;width: 20%;display: flex;align-items: center">{{ item.points }}</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="section-cnr_content">
        <div class="main" v-if="noticeArr.length > 0">
          <h4>
            <span>通知公告</span>
            <el-button
              @click="$router.push('/notice')"
              size="mini"
              icon="el-icon-arrow-right"
            ></el-button>
          </h4>
          <ul>
            <li v-for="(item, i) in noticeArr" :key="i">
              <div class="item" @click="handleDetail(item)">
                <h5>{{ item.title }}</h5>
                <p>{{ item.releaseTime }}</p>
              </div>
            </li>
          </ul>
          <!-- <div v-for="(item, i) in noticeArr" :key="i" class="item">
            <el-image
              class="img"
              :src="item.img || noticeImg"
              fit="cover"
            ></el-image>
            <div class="text">
              <h4 class="cf">
                <span class="tag fl">通知</span>
                <span class="fl title">{{ item.title }}</span>
                <span class="fr releaseTime">{{ item.releaseTime }}</span>
              </h4>
              <button class="btn" @click="handleDetail(item)">查看详情</button>
            </div>
          </div> -->
        </div>
        <empty-box v-else>暂无通知</empty-box>
      </div>
    </div>
    <div class="aside">
      <ul>
        <li
          v-for="(item, i) in asideNav"
          :key="i"
          :style="{ backgroundImage: 'url(' + item.bc + ')' }"
        >
          <router-link :to="item.hash">
            <h4>{{ item.title }}</h4>
            <p>{{ item.text }}</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import noticeImg from "@/assets/img/notice.svg";
import noticeAsideBc1 from "@/assets/img/noticeAsideBc1.jpg";
import noticeAsideBc2 from "@/assets/img/noticeAsideBc2.jpg";
import noticeAsideBc3 from "@/assets/img/noticeAsideBc3.jpg";
import {set} from "@/utils/local.js"
import emptyBox from "@/components/empty.vue";
export default {
  components: { emptyBox },
  props: ["noticeArr", "siteSlideshow", "top10"],
  data() {
    return {
      noticeImg: noticeImg, // 通知的默认图片
      asideNav: [
        {
          bc: noticeAsideBc1,
          title: "场景概览",
          text: "从传统文化到未来科技，总有你的中意。",
          hash: "/scene",
        },
        {
          bc: noticeAsideBc3,
          title: "课程信息",
          text: "乐学乐玩乐生活，有趣的课程都在这里。",
          hash: "/course",
        },
        // {
        //   bc: noticeAsideBc2,
        //   title: "志愿者招募",
        //   text: "热心公益的你，加入我们吧。",
        //   hash: "/volunteer",
        // },
      ],
    };
  },
  methods: {
    // 查看详情
    handleDetail(item) {
      set("notice",item)
      this.$router.push("/notice/noticeInfo?noticeId=" + item.id);
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.root {
  display: flex;
  margin-top: 50px;
  .section-cnr {
    flex: 1;
    height: 375px;
    box-sizing: border-box;
    display: flex;
    .section-cnr_slide {
      width: 50%;
      //margin-right: 10px;
      //padding: 20px;
      padding-top: 0;
      //box-shadow: 0 0 10px #eee;
      box-sizing: border-box;
      /deep/.el-carousel__container {
        height: 400px;
        img {
          width: 100%;
          height: 375px;
        }
      }
    }
    .section-cnr_content {
      width: 60%;
      .main {
        background-image: url("../../../assets/img/noticeBc.png");
        height: calc(100% - 40px);
        padding: 20px;
        h4 {
          color: #fff;
          font-size: 24px;
          display: flex;
          justify-content: space-between;
          span {
            font-weight: 300;
          }
          .el-button--mini {
            padding: 4px 3px 4px 4px;
            background-color: transparent;
            color: #fff;
            font-size: 18px;
          }
        }
        ul {
          li {
            padding: 12px 0;
            border-bottom: 1px dashed hsla(0, 0%, 100%, 0.8);
            color: #fff;
            line-height: 1.2em;
            &:last-child {
              border-bottom: 0;
            }
            .item {
              cursor: pointer;
              h5 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              p {
                font-size: 12px;
              }
            }
          }
        }
        // .item {
        //   padding: 30px;
        //   background-color: white;
        //   border-radius: 8px;
        //   box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
        //   display: flex;
        //   margin-bottom: 20px;
        //   .img {
        //     margin-right: 20px;
        //     height: 60px;
        //     width: 100px;
        //     border-radius: 8px;
        //   }
        //   .text {
        //     flex: 1;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;
        //     align-items: flex-end;
        //     h4 {
        //       line-height: 24px;
        //       width: 100%;
        //       .tag {
        //         margin-right: 20px;
        //         padding: 0 10px;
        //         background-color: @title-color;
        //         color: #fff;
        //         font-size: 12px;
        //         border-radius: 4px;
        //         cursor: default;
        //       }
        //       .title {
        //         font-weight: 700;
        //       }
        //       .releaseTime {
        //         font-size: 14px;
        //         color: #999;
        //       }
        //     }
        //     .btn {
        //       height: 26px;
        //       width: 100px;
        //       border-radius: 13px;
        //       background-color: @title-color;
        //     }
        //   }
        // }
      }
    }
  }
  .aside {
    margin-left: 30px;
    //flex:1;
    width: 40%;
    ul {
      display: flex;
      li {
        flex:1;
        height: 375px;
        background-size: 100% 100%;
        a {
          padding: 20px;
          display: block;
          height: calc(100% - 40px);
          color: #fff;
          line-height: 2em;
          h4 {
            margin-bottom: 10px;
            font-size: 20px;
            text-shadow: 3px 3px 3px #aaa;
          }
          p {
            text-shadow: 2px 2px 2px #aaa;
          }
        }
      }
    }
  }
}
.bg1{background: #eae8e8}
.bg2{background: #f73131;color: #fff;border-radius: 28%;width: 22px;margin: 0 1em;font-size: 0.9em;padding: 0.1em; box-sizing: border-box}
.bg3{background: #ff6600;color: #fff;border-radius: 28%;width: 22px;margin: 0 1em;font-size: 0.9em;padding: 0.1em;  box-sizing: border-box}
.bg4{background: #ffc20d;color: #fff;border-radius: 28%;width: 22px;margin: 0 1em;font-size: 0.9em;padding: 0.1em;  box-sizing: border-box}
.bg5{background: #c5c7cd;color: #fff;border-radius: 28%;width: 22px;margin: 0 1em;font-size: 0.9em;padding: 0.1em;  box-sizing: border-box}
</style>
