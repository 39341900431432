<template>
    <!-- 热门场景 -->
    <div class="scene-root">

      <div class="left">
        <el-row v-if="sceneArr.length > 0" :gutter="10">
          <el-col :span="sceneArr.length==1?24:sceneArr.length==2?12:8" v-for="(item, i) in sceneArr" :key="i">
            <div
              @click="$router.push('/scene/sceneInfo?siteId=' + item.id)"
              class="top"
            >
              <el-image
                class="img"
                :src="item.sitePicture"
                fit="cover"
              ></el-image>
            </div>
            <div class="bottom">
              <h4>{{ item.siteName }}</h4>
              <div class="reservationNumber el-icon-user">
                {{ item.siteHeat }}人次已预约
              </div>
            </div>
          </el-col>
        </el-row>
        <empty-box v-else>暂无场景</empty-box>
      </div>
            <div class="right">
        <h3>场景分区</h3>
        <el-button @click="$router.push('/scene')">查看更多场景</el-button>
      </div>
    </div>
</template>

<script>
import emptyBox from "@/components/empty.vue";
export default {
  components: { emptyBox },
  props:["sceneArr"],
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #007db8;
.scene-root {
  margin-bottom: 50px;
  display: flex;
  .right {
    background-image: url("../../../assets/img/scene_bg.0ca8934.png");
    height: 240px;
    width: 200px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .el-button {
      color: #fff;
      background-color: #76b4d9;
    }
  }
  .left {
    flex: 1;
    height: 240px;
    box-sizing: border-box;
    .el-row {
      height: 100%;
      .el-col {
        position: relative;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        &:hover .top .img {
          transition: transform 1.35s;
          transform: scale(1);
        }
        &:hover .bottom {
          bottom: 0;
          transition: bottom 0.35s;
        }
        .top {
          height: 100%;
          overflow: hidden;
          .img {
            transform: scale(1.12);
            transition: transform 1.35s;
            height: 100%;
            width: 100%;
          }
        }
        .bottom {
          padding: 6px 20px;
          height: 60px;
          box-sizing: border-box;
          position: absolute;
          width: calc(100% - 10px);
          background-color: rgba(0, 0, 0, 0.5);
          color: #fff;
          line-height: 1.5em;
          bottom: -60px;
          transition: bottom 0.35s;
          h4 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .reservationNumber {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
