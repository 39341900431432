<template>
	<div class="course-root">
		<!-- <div class="section-title">
      <div class="title">热门课程</div>
      <router-link to="/course" v-slot="{ navigate }">
        <div @click="navigate" class="more">更多>></div>
      </router-link>
    </div> -->
		<div class="main">
			<div class="introduce">
				<div class="content">
					<!-- <h4>{{ intCourse.title }}</h4> -->
					<p v-html="intCourse.introduce"></p>
				</div>
				<router-link class="more" tag="button" to="/course"
					>了解更多</router-link
				>
				<el-image
					class="img"
					:src="intCourse.img"
					fit="contain"
				></el-image>
			</div>
			<div class="list">
				<div v-if="courseArr.length > 0" class="list_item">
					<el-card
						v-for="item in courseArr"
						:key="item.id"
						:style="itemStyle"
					>
						<div class="image-box">
							<el-image
								class="image"
								:src="item.coursePicture"
								fit="cover"
							>
								<div slot="error" class="image-error">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</div>
						<div class="text">
							<h5 class="wrap">{{ item.courseName }}</h5>
							<p class="wrap">
								<i class="el-icon-office-building"></i>
								{{ item.siteName || item.dept }}
							</p>
							<p v-if="item.siteAddress" class="wrap">
								<i class="el-icon-location-information"></i>
								{{ item.siteAddress }}
							</p>
							<div>
								<span v-if="item.courseLimitGrade"
									><i class="el-icon-circle-check"></i
									>{{ item.courseLimitGrade }}</span
								>
								<span
									v-for="(val, j) in item.courseLabel"
									:key="j"
									><span
										v-for="(dict, i) in $store.state
											.courseLabelDict"
										:key="i"
										><span v-if="dict.dictKey == val">
											<i class="el-icon-circle-check"></i
											>{{ dict.dictValue }}</span
										></span
									></span
								>
							</div>
							<!-- <div v-if="item.courseTimeType==1" class="time">
                <p>开课时间：</p>
                <p>{{ item.courseDay + item.timer }}</p>
              </div> -->
						</div>
						<button @click="handleAppointment(item)" class="btn">
							查看详情
						</button>
					</el-card>
					<el-card
						:style="moreBtnStyle"
						@click.native="$router.push('/course')"
						><p class="iconfont icon-gengduo"></p
					></el-card>
				</div>
				<empty-box v-else>暂无课程</empty-box>
			</div>
		</div>
	</div>
</template>

<script>
import courseImg from '@/assets/img/course.svg'
import emptyBox from '@/components/empty.vue'
import { set } from '@/utils/local.js'
export default {
	components: { emptyBox },
	props: ['courseArr'],
	data() {
		return {
			intCourse: {
				img: courseImg,
				title: '热门课程',
				introduce: '不知道选什么？<br><br>先看看热门的！',
			},
			moreBtnStyle: {
				// 最后一个更多按钮的动态设置css
				width: '32%',
				height: '260px',
			},
			itemStyle: {
				// 每个课程的样式动态设置
				width: '32%',
				height: '260px',
			},
		}
	},
	methods: {
		// 根据数据量,显示不同样式
		setStyle() {
			if (this.courseArr.length === 1) {
				this.moreBtnStyle.height = this.itemStyle.height = '540px'
				this.itemStyle.width = '70%'
				this.moreBtnStyle.width = '30%'
			} else if (
				this.courseArr.length === 2 ||
				this.courseArr.length === 3
			) {
				this.moreBtnStyle.width = '100%'
				this.moreBtnStyle.height =
					this.courseArr.length === 2 ? '25%' : '30%'
				this.itemStyle.width = 100 / this.courseArr.length - 1 + '%'
				this.itemStyle.height =
					'calc(' +
					(this.courseArr.length === 2 ? 75 : 70) +
					'% - 20px)'
			} else {
				this.moreBtnStyle = this.itemStyle = {
					width: '32%',
					height: '260px',
				}
			}
		},
		// 立即预约
		handleAppointment(item) {
			set('course', item)
			this.$router.push('/course/courseInfo?courseId=' + item.id)
		},
	},
	created() {
		this.setStyle()
	},
}
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #007db8;
@introduce-color: #76b4d9;
.course-root {
	padding: 30px 0;
	.main {
		display: flex;
		height: 570px;
		// border-radius: 8px;
		background-color: #e0e8ea;
		overflow: hidden;
		.introduce {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			width: 260px;
			// border-top-left-radius: 8px;
			// border-bottom-left-radius: 8px;
			background-color: @introduce-color;
			.content {
				padding: 15px;
				line-height: 1.5em;
				color: #fff;
				h4 {
					margin-bottom: 30px;
					text-align: center;
					font-size: 26px;
					font-weight: 700;
				}
				p {
					// text-indent: 2em;
					letter-spacing: 1px;
					text-align: left;
				}
			}
			.more {
				width: 100px;
				height: 40px;
				border-radius: 20px;
				font-size: 14px;
				font-weight: 700;
				color: @introduce-color;
				background-color: #fff;
				border: 0;
				outline: none;
				&:hover {
					cursor: pointer;
					background-color: #e4f0f7;
				}
			}
			.img {
				width: 100%;
				height: 200px;
				animation: swinging 30s linear 0s infinite;
			}
		}
		.list {
			flex: 1;
			padding: 15px;
			.list_item {
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.el-card {
					position: relative;
					padding: 15px;
					box-sizing: border-box;
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {
						margin-bottom: 20px;
					}
					/deep/.el-card__body {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						padding: 0;
					}
					.image-box {
						width: 100%;
						flex: 1;
						border-radius: 5px;
						margin-bottom: 5px;
						overflow: hidden;
						.image {
							width: 100%;
							height: 100%;
							transition: transform 1.35s;
							transform: scale(1.12);
						}
					}
					.text {
						// height: 124px;
						height: 95px;
						line-height: 1.8em;
						font-size: 14px;
						.wrap {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 12px;
							color: #888;
						}
						h5.wrap {
							font-size: 14px;
							color: #333;
						}
						p,
						div {
							white-space: nowrap; /* 规定文本是否折行 */
							overflow: hidden; /* 规定超出内容宽度的元素隐藏 */
							text-overflow: ellipsis;
							span {
								margin-right: 5px;
								color: #666;
								span {
									margin-right: 0;
								}
								.el-icon-circle-check {
									color: @btn-color;
								}
							}
						}
						.time {
							margin-top: 5px;
							color: @btn-color;
						}
					}
					.btn {
						position: absolute;
						transition: bottom 0.35s;
						bottom: -60px;
						left: 0;
						width: 100%;
						height: 60px;
						font-size: 16px;
						background-color: @btn-color;
					}
					&:hover .btn {
						bottom: 0px;
						transition: bottom 0.35s;
					}
					&:hover .image-box .image {
						transition: transform 1.35s;
						transform: scale(1);
					}
					&:last-child:hover {
						cursor: pointer;
						background-color: rgba(0, 0, 0, 0.1);
						text-shadow: 1px 2px 6px #444;
						box-shadow: inset 0px 0px 6px 1px #888;
					}
					.icon-gengduo {
						text-align: center;
						font-size: 60px;
						color: @btn-color;
					}
				}
			}
		}
	}
}
@keyframes swinging {
	0% {
		-webkit-transform: scale(1);
	}
	10%,
	20% {
		-webkit-transform: scale(0.8) rotate(-20deg);
	}
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale(1) rotate(20deg);
	}
	40%,
	60%,
	80% {
		-webkit-transform: scale(0.8) rotate(-20deg);
	}
	100% {
		-webkit-transform: scale(1) rotate(0);
	}
}
</style>
