<template>
	<div>
		<div class="slideBc">
			<!-- 轮播 -->
			<Slide :slideshow="slideshow" :index="true" />
		</div>
		<div class="containar">
			<div :class="notice ? 'notice show' : 'notice'" ref="notice">
				<!-- 通知公告 -->
<!--        <Notice :siteSlideshow="siteSlideshow" :noticeArr="noticeArr" :top10="top10"/>-->
        <Notice :siteSlideshow="siteSlideshow" :noticeArr="noticeArr"/>
			</div>
			<div :class="course ? 'course show' : 'course'" ref="course">
				<!-- 热门课程 -->
				<Course v-if="isCourseOk" :courseArr="courseArr" />
			</div>
			<div :class="scene ? 'scene show' : 'scene'" ref="scene">
				<!-- 热门场景 -->
				<Scene :sceneArr="sceneArr" />
			</div>
		</div>
	</div>
</template>

<script>
import Slide from '@/components/slide'
import Scene from './components/scene'
import Course from './components/course'
import Notice from './components/notice'

import moment from 'moment'

import { indexSlide_api , getScoreRanking_api } from '@/api/index.js'
import {
	getCourseList_api,
	getCourseInfo_dic,
	getCourseState_dic,
} from '@/api/course.js'
import { getQuestion_api } from '@/api/user'
import { getNoticeList_api } from '@/api/notice.js'
import { sceneSlide_api, getSceneList_api } from '@/api/scene'
export default {
	components: {
		Slide,
		Scene,
		Course,
		Notice,
	},
	data() {
		return {
			slideshow: [], // 轮播数据（首页顶部）
			siteSlideshow: [], // 轮播（场景）
      courseArr: [], // 课程数据
      top10: [], // 积分top10数据
			isCourseOk: false, // 课程数据是否准备好
			noticeArr: [], // 通知数据
			sceneArr: [], // 场景数据
			scene: false,
			course: false,
			notice: false,
			// 是否加show
			clientHeight: document.documentElement.clientHeight, // 当前屏幕显示的高度
		}
	},
	methods: {
		// 滚动条滚动
		handleScroll() {
			if (
				!this.scene &&
				this.$refs.scene.offsetTop - window.scrollY <= this.clientHeight
			) {
				this.scene = true
			}
			if (
				!this.course &&
				this.$refs.course.offsetTop - window.scrollY <=
					this.clientHeight
			) {
				this.course = true
			}
			if (
				!this.notice &&
				this.$refs.notice.offsetTop - window.scrollY <=
					this.clientHeight
			) {
				this.notice = true
			}
		},
		// 获取轮播数据（顶部轮播）
		async getSlideImg() {
			let { data, code } = await indexSlide_api({
				tenantId: this.$tenantId,
			})
			if (code == 200) this.slideshow = data
		},
		// 获取轮播数据(通知公告处轮播,和场景列表页轮播共用)
		async getSceneSlideImg() {
			let { data, code } = await sceneSlide_api({
				tenantId: this.$tenantId,
			})
			if (code == 200) {
				this.siteSlideshow = data
				this.$store.commit('updateSiteSlideshow', data)
			}
		},
    //获取积分top10
    async getScoreRanking() {
      /* let { data, code } = await getScoreRanking_api({
        tenantId: this.$tenantId,
      })
      if (code == 200) this.top10 = data.slice(0,10) */
    },
		// 获取热门课程列表
		async getCourseList() {
			let { data, code } = await getCourseList_api({
				tenantId: this.$tenantId,
				size: 5,
				desc: 'course_heat',
				page: 1,
				courseStatus: 1,
			})
			if (code !== 200) return null
			// 数据处理
			data.records.forEach((item) => {
				if (item.courseLimitGrade) item.courseLimitGrade += '年级'
				if (item.courseLabel && typeof item.courseLabel === 'string')
					item.courseLabel = item.courseLabel.split(',')
				// 开始时间
				let sTime = moment(new Date(item.courseStartTime)).format(
					'HH:mm'
				)
				// 结束时间
				let eTime = moment(new Date(item.courseEndTime)).format('HH:mm')

				item.timer = sTime + '-' + eTime
				item.time =
					moment(new Date(item.courseStartWeek)).format(
						'YYYY-MM-DD'
					) +
					' 至 ' +
					moment(new Date(item.courseEndWeek)).format('YYYY-MM-DD') +
					' 每周' +
					item.courseDay +
					' ' +
					item.timer
			})
			this.courseArr = data.records
			this.isCourseOk = true
		},

		// 获取通知公告数据
		async getNoticeList() {
			let { data, code } = await getNoticeList_api({
				tenantId: this.$tenantId,
				size: 5,
				sort: 'create_time',
				sortType: 'desc',
				page: 1,
			})
			if (code == 200) this.noticeArr = data.list
		},
		// 获取场景资源数据
		async getSceneList() {
			let { data, code } = await getSceneList_api({
				tenantId: this.$tenantId,
				size: 3,
				sort: 'site_heat',
				sortType: 'desc',
				current: 1,
			})
			if (code != 200) return null
			this.sceneArr = data.records
		},
		//课程标签字典
		async courseDict() {
			let { data, code } = await getCourseInfo_dic({})
			if (code == 200) {
				this.$store.commit('updateCourseDict', data)
			}
		},
		//课程状态字典
		async courseStateDict() {
			let { data, code } = await getCourseState_dic({})
			if (code == 200) {
				this.$store.commit('updateCourseStateDict', data)
			}
		},
		//调查问卷提示框
		open() {
			// 获取角色
			let role =
				this.$store.state.userInfo.roleCode ||
				this.$store.state.userInfo.role_code
			if (role == 'student') {
				this.getQuestion()
			}
		},
		async getQuestion() {
			let { msg, code, data } = await getQuestion_api({})
			if (code == 200) {
				if (data.questionFlag) {
					this.$notify({
						title: '提示',
						type: 'warning',
						offset: 80,
                        dangerouslyUseHTMLString: true,
                        message:'<a href="#/user/questionNaire/questionNaire">请前往个人中心填写问卷调查</a>'
					})
				}
			}
		},
	},

	created() {
		this.getSlideImg()
		this.getSceneSlideImg()
    this.getScoreRanking()
		this.getSceneList()
		this.getCourseList()
		this.getNoticeList()
		this.courseDict()
		this.courseStateDict()
		this.open()
	},
	mounted() {
			window.addEventListener('scroll', this.handleScroll)
			this.handleScroll() // 初始调用
		},
		beforeDestroy() {
			// 解绑滚动事件
			if (window.removeEventListener) {
				window.removeEventListener('scroll', this.handleScroll, false)
			} else {
				window.detachEvent('onscroll', this.handleScroll)
			}
		},
	}
</script>

<style lang="less" scoped>
.slideBc {
	background-image: url('../../assets/img/sceneInfoBc2.jpg');
	background-size: 100% 100%;
}
.containar {
	width: 60vw;
	overflow: hidden;
	.scene {
		position: relative;
		left: -80%;
		opacity: 0;
		&.show {
			left: 0;
			opacity: 1;
			transition: all 2.5s cubic-bezier(0.68, 0, 0.27, 1.55);
		}
	}
	.course {
		position: relative;
		right: -80%;
		opacity: 0;
		&.show {
			right: 0;
			opacity: 1;
			transition: all 2.5s cubic-bezier(0.68, 0, 0.27, 1.55);
		}
	}
	.notice {
		position: relative;
		left: -80%;
		opacity: 0;
		&.show {
			left: 0;
			opacity: 1;
			transition: all 2.5s cubic-bezier(0.68, 0, 0.27, 1.55);
		}
	}
}
</style>
